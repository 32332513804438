const SUBJECT_STATUS = {
    FINISHED: "FINISHED",
    PUBLISHED: "PUBLISHED",
    DRAFT: "DRAFT"
};

const FORM_TYPE_MAP = {
    discussion: "FORUM",
    allocation: "ALLOCATION",
    choice: "CHOICE",
};


module.exports = {
    SUBJECT_STATUS,
    FORM_TYPE_MAP
}